<template>
  <div class="regions">
    <p v-if="regionList" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ regionList.data.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="regionList"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filter"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(id)="data">
          <div>{{ data.index + 1 + (currentPage - 1) * perPage }}</div>
        </template>
        <template #cell(auto)="data">
          <button
            class="table-name"
            @click="$router.push(`/database/info-transport/${data.item.id}`)"
          >
            {{ data.item.model ? data.item.model.name : "" }}
          </button>
        </template>
        <template #cell(details)="data">
          <span v-if="data.item.country_id === 1" class="cities">Шымкент</span>
          <span v-else class="cities">{{data.item.country_id}}</span>
        </template>
        <template #cell(cities)="data">
          <span v-for="city in data.item.cities" :key="city.id" class="cities">{{
            city.name
          }}</span>
        </template>
        <template #cell(day)="data">
          <span class="cities">{{
            data.item.schedule
              ? data.item.schedule
                  .map((e) => weekList.find((week) => week.value === e.day).week)
                  .join(", ")
              : ""
          }}</span>
        </template>
        <template #cell(time)="data">
          <span class="cities">{{
            data.item.schedule ? data.item.schedule.map((e) => e.time.slice(0, 5)).join(", ") : ""
          }}</span>
        </template>
        <template #cell(action)="data">
          <div>
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-region?id=${data.item.id}`)"
              />
            </a>
            <a @click="deleteHandler(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </div>
        </template>
      </b-table>
    </div>
    <!--    <RegionsFormModal :data="data" @close="data=null" />-->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      swowItem: null,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "title",
          label: "Направления",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "cities",
          label: "Города",
          sortable: false,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "details",
          label: "Место выезда",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "day",
          label: "День выезда",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "time",
          label: "Время выезда",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "cost",
          label: "Стоимость",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      weekList: [
        { week: "Пн", value: "isMonday" },
        { week: "Вт", value: "isTuesday" },
        { week: "Ср", value: "isWednesday" },
        { week: "Чт", value: "isThursday" },
        { week: "Пт", value: "isFriday" },
        { week: "Сб", value: "isSaturday" },
        { week: "Вс", value: "isSunday" },
      ],
      stateData: null,
    };
  },
  computed: {
    rows() {
      if (this.regionList && this.regionList.data) {
        this.$emit("spinner");
        return this.regionList.data.length;
      }
      return 3;
    },
    ...mapState(["regionList", "textFilter"]),
    filter() {
      return this.regionList.data.filter((e) => e.title?.indexOf(this.textFilter || "") > -1) || [];
    },
  },
  created() {
    this.$store.dispatch("getRegionList");
  },
  methods: {
    editCity(item) {
      this.stateData = item;
    },
    deleteHandler(region_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post("/web/delete-region", {
            region_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getRegionList");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  },
  components: {
    // RegionsFormModal: () => ({
    //   component: import('./regionForm')
    // })
  },
};
</script>

<style scoped>
.table-name {
  background: transparent;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  color: #707070;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.cities td.input {
  width: 50px;
}
.regions .cities:not(:last-child):after {
  content: ", ";
}
.car-one {
  width: 250px;
}
</style>
